<template>
    <div>
        <!-- /.modal -->
        <div class="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form @submit.prevent="save" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="exampleModalLabel"> <img src="../../../public/sensor.png"> {{ form.id && isDetail ? "Detail Sensor" : form.id ? "Edit Sensor" : "Add Sensor"}}</h5>
                        </div>
                        <div class="modal-body">
                            <div class="col">
                                <div class="form-group">
                                    <label for="code" class="col-form-label">Code Sensor<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="code-sensor" required v-model="form.code" placeholder="Ex. 123ABC"
                                        :disabled="loading || isDetail">
                                </div>
                                <div class="form-group">
                                    <label for="title" class="col-form-label">Sensor Name<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="title" required placeholder="Ex. Sensor Jatuh"
                                        v-model="form.title" :disabled="loading || isDetail">
                                </div>
                                <div class="form-group">
                                    <label for="category" class="col-form-label">Sensor Category <span class="text-red">*</span></label>
                                    <select2 v-model="form.category.id" :config="categoryConfig" required :disabled="loading || isDetail" class="form-control">
                                        <option :value="form.category.id" v-if="form.category.id">{{ form.category.name }}</option>
                                    </select2>
                                </div>
                                <div class="row">
                                    <div class="col-sm-8">
                                        <div class="form-group">
                                            <label for="default-value" class="col-form-label">Default Value<span class="text-red">*</span></label>
                                            <input type="text" class="form-control" id="default-value" required placeholder="Ex. 100"
                                                v-model="form.default_value" :disabled="loading || isDetail">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="uom" class="col-form-label">UoM<span class="text-red">*</span></label>
                                            <input type="text" class="form-control" id="uom" required placeholder="Ex. Celcius"
                                                v-model="form.uom" :disabled="loading || isDetail">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="description" class="col-form-label">Descriptions</label>
                                    <textarea type="text" class="form-control" id="description" placeholder="Short Description"
                                        v-model="form.description" :disabled="loading || isDetail"> </textarea>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="resetSensor">Close</button>
                            <div v-if="!isDetail">
                                <button type="submit" class="btn btn-primary" :disabled="loading">
                                    <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                    {{ loading ? 'Saving ...' : 'Save' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <form @submit.prevent="deleteData" class="card">
                    <div class="modal-content">
                        <div class="modal-header bg-primary">
                            <h5 class="modal-title" id="deleteModalLabel"> <img src="../../../public/sensor.png"> Delete Sensor</h5>
                        </div>
                        <div class="modal-body">
                            <div class="col">
                                <div class="form-group">
                                    <span>Are you sure you want to delete?</span>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <div v-if="!isDetail">
                                <button type="submit" class="btn btn-primary" :disabled="loading">
                                    <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                                    {{ loading ? 'Delete ...' : 'Delete' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark capitalize">
                            Sensor
                        </h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right ">
                            <!-- <li>
                                <button type="button" class="btn btn-block btn-outline-primary btn-lg">Export</button>
                            </li> -->
                            <li class="ml-3"><button type="button" class="btn btn-block btn-primary btn-lg"
                                    @click="showModal"><span class="fas fa-plus"> </span> Add Sensor</button></li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>

        <data-tables-master-data :config="config" class="container-fluid" id="sampleTable"></data-tables-master-data>

    </div>
</template>


<script>
import DataTablesMasterData from '@components/DataTablesMasterData.vue'
import { render } from 'sass'
const {console} = window
export default {
    components: {
        DataTablesMasterData
    },

    data() {
        return {
            active: false,
            errorDialog: null,
            errorText: '',
            uploadFieldName: 'file',
            error: undefined,
            loading: false,
            text_validation: null,
            maxSize: 1024,
            form: {
                id: null,
                code: "",
                title: "",
                default_value: "",
                uom: "",
                description: "",
                category: {
                    id: null
                }
            },
            disabled: false,
            isDetail : false,
            categoryConfig: {},
            config: {
                columns: [{
                    title: 'Code Sensor',
                    data: 'code'
                }, {
                    title: 'Sensor Name',
                    data: 'title'
                }, {
                    title: 'Default Value',
                    data: 'default_value'
                }, {
                    title: 'UoM',
                    data: 'uom'
                }, {
                    title: 'Sensor Category',
                    data: 'category.name',
                }, {
                    title: 'Action',
                    data: 'id',
                    // ordering: false,
                    render: (val) => {
                        return `
                        <div> 
                            <a 
                                href="javascript:void" 
                                data-id="${val}" 
                                class="editor-detail" 
                                data-action="detail" 
                                data-toggle="tooltip" 
                                data-placement="top" 
                                title="Click to detail"
                                style="color:#9E9E9E;margin-right:10px;"
                            >
                                <i class="fa fa-eye"></i>
                            </a>
                            <a 
                                href="javascript:void" 
                                data-id="${val}" 
                                class="editor-edit" 
                                data-action="edit" 
                                data-toggle="tooltip" 
                                data-placement="top" 
                                title="Click to edit"
                                style="color:#9E9E9E;margin-right:10px;"
                            >
                                <i class="fa fa-edit"></i>
                            </a>
                            <a 
                                href="javascript:void" 
                                data-id="${val}" 
                                class="editor-delete" 
                                data-action="delete" 
                                data-toggle="tooltip" 
                                data-placement="top" 
                                title="Click to delete"
                                style="color:#9E9E9E;"
                            >
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>`
                    }
                }],
                "drawCallback": (settings ) => {
                        $(".editor-detail").on( 'click', (e) => {
                            this.getDetail($(e.target).closest('a').data('id'))
                        });

                        $(".editor-edit").on( 'click', (e) => {
                            this.getEdit($(e.target).closest('a').data('id'))
                        });

                        $(".editor-delete").on( 'click', (e) => {
                            this.showModalDelete($(e.target).closest('a').data('id'))
                        });
                },
                url: this.Api.base + '/sensor',
                order: [[0, "desc"]],
                // filter: []
            }
        }
    },
    mounted() {
        this.initData();
    },
    props: {
        value: Object,
    },
    methods: {
        add(val) {
            $('#exampleModal').modal('show');
        },
        getDetail(id) {
            this.Api.get(`/sensor/${id}`).then((res) => {
                console.log(res.data)
                if(res.data) {
                    this.form = {...res.data}
                    this.form.category = res.data.category || {}
                    this.isDetail = true
                    $('#exampleModal').modal('show');
                }
            }).catch((e) => {
                console.log(error)
            })
        },
        async getEdit(id) {
            try {
                const res = await this.Api.get(`/sensor/${id}`)
                if(res.data) {
                    this.form = {...res.data}
                    this.form.category = res.data.category || {}
                    this.isDetail = false
                    $('#exampleModal').modal('show');
                }
            } catch (error) {
                console.log(error)
            }
        },
        initData() {
            this.categoryConfig = {
                ajax: {
                    url: env.api.base + '/sensorcategory?sort=id',
                    data: params => {
                        var filters = [
                            ["name", "like", params.term]
                        ]
                        var query = {
                            sort: "name",
                            filters: params.term ? JSON.stringify(filters) : "",
                            page: params.page ? params.page : 1
                        }

                        return query
                    },
                    processResults: (data, params) => {
                        data.content.forEach(item => {
                            item.text = item.name
                        })
                        return {
                            results: data.content,
                            pagination: {
                                more: data.last == false
                            }
                        }
                    }
                }
            }
        },
        showModalDelete(id) {
            $('#deleteModal').modal('show');
            this.form.id = id
        },
        async deleteData() { 
            this.loading = true
            if(this.form.id) {
                try {
                    await this.Api.delete(`/sensor/${this.form.id}`)
                    this.loading = false,
                    $('#deleteModal').modal('hide'),
                    this.refresh()
                } catch (error) {
                    alert('Delete Failed')
                }
            }
        },
        resetSensor() {
            this.form = {
                code : "",
                title : "",
                category : {
                    id: null
                },
                default_value : "",
                uom : "",
                description : ""
            }
        },
        showModal() {
            this.isDetail = false;
            this.resetSensor()
            $("#exampleModal").modal("show");
        },
        defaultBtnImage() {
            const defaultbtn = document.querySelector("#default-btn");
            defaultbtn.click();
        },
        save() {
            this.loading = true
            if(this.form.id) {
                this.Api.put(`/sensor/${this.form.id}`, this.form).then(res => {
                this.loading = false;
                this.initData();
                this.resetSensor();
                this.refresh();
                $('#exampleModal').modal('hide');
            }).catch((e) => {
                this.loading = false
                this.error = e.response.data.message
                this.text_validation = "Submit Failed";
            })
            } else {
                this.Api.post('/sensor', this.form).then(res => {
                    this.loading = false;
                    this.initData();
                    this.refresh();
                    this.resetSensor();
                    $('#exampleModal').modal('hide');
                }).catch((e) => {
                    this.loading = false
                    this.error = e.response.data.message
                    this.text_validation = "Submit Failed";    
                })
            }
        },
        refresh() {
            this.config = {
                url: this.Api.base + '/sensor'
            }
        }
    },
}
</script>
